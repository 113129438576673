@import "../../styles/_shared.scss";


.secondary-footer {
  position: relative;
  padding: 30px 0 15px 0;
  text-align: center;
  background-color: #000;
  transform: translateZ(0);
  font-size: 11px;
  font-weight: 400;
  // margin-top: 30px;
  .legacy-link-menu {
    text-align: left;
    margin-left: -10px;
    @media (max-width: 576px) { 
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .footer-meta:first-child, .footer-meta:first-child > a {
    text-transform: capitalize;
  }
  a, span {
      padding: 0 5px;
      display: inline-block;
      color: #fff;
      @media (max-width: 576px) { 
        padding-top: 5px;
        padding-bottom: 5px;
      }
  }

  .footer-meta {
      position: relative;
      a {
        font-size: 14px;
        line-height: 20px;
        &:hover{
          color: #00A19F;
        }
      }
      &::after {
        @media (min-width: 576px) {
        position: absolute;
        right: 0;
        height: 15px;
        width: 1px;
        background-color: #ddd;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        }
    }
    &.f-meta-l {
      &::after {
          display: none;
      }
    }
  }
  .formswift-button {
    @media (max-width: 576px) { 
      display: none; 
    }
  }
  p {
    line-height: 20px;
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    text-align: left;
  }
  @media(max-width:767px) {
    p {
      text-align: center;
    }
  }
}