
@import "../../styles/_shared.scss";

.header {
  min-height: 60px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9999;
  .container {
    overflow: unset;
  }
  .header-wrapper {
    display: flex;
    align-items: center;
    height: 60px;
    .menu-logo-wrap {
      display: flex;
      align-items: center;
      @media (max-width: 576px) { 
        margin-left: 20px;
      }
      img {
        /* height: 108px;
        width: 108px; */
        width: 150px;
        position: relative;
        top:-4px;
      }
    }
    .header-right-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 576px) { display: none; }
      ul {
        height: 100%;
        li {
          float: left;
          padding: 0;
          list-style: none;
          height: 100%;
          &.relt-menu-item {
            position: relative;
          }
          a {
            padding: 18px 20px 15px 20px;
            display: flex; 
            .fa-chevron-down {
              margin-left: 5px;
              color: #38454f;
              transform: rotate(0deg);
              transition: all .5s ease; 
            }
          }
          &:hover > a {
            color: #00A19F;
          }
          &:hover > a span.eezee-arrow-icon {
            transform: rotate(180deg);
            transition: all .5s ease; 
            color: #00A19F;
          }
          &:hover > .navbar-dropdown-inner1 .header-container {
            display: block;
            opacity: 1;
            height: auto;
            transform: translateY(0);
            transition: all .5s ease;
          }
          &:hover > .navbar-dropdown-inner2 .header-container {
            display: block;
            opacity: 1;
            height: auto;
            -ms-transform: translateY(0);
            transform: translateY(0);
            transition: all .5s ease;
          }
        }
      }
      ul.support-menu {
        li {
          height: 100%;
          position: relative;
          margin-top: 12px;
          a {
            border: 1px solid #444;
            border-radius: 4px;
            padding: 5px 22px 7px 22px;
          }
          &:hover > .sign-in-dropdown {
            display: block;
            opacity: 1;
            height: auto;
            -ms-transform: translateY(0);
            transform: translateY(0);
            transition: all .5s ease;
          }
          .sign-in-dropdown {
            position: absolute;
            width: 266px;
            background: #fff;
            z-index: 999;
            padding: 15px 0;
            display: none;
            height: 0;
            opacity: 0;
            right: 0;
            border: 1px solid #ddd;
            border-radius: 0 0 8px 8px;
            top: 48px;
            border-top-color: #00A19F;
            transition: all .3s ease-in-out;
            &::after {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              border: 1px solid #00A19F;
              left: 74%;
              top: -7px;
              border-right: 0;
              border-bottom: 0;
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              background: #fff;
            }
            .sign-in-link {
              background-color: #00A19F;
              border-radius: 4px;
              padding: 12px;
              display: block;
              color: #fff;
              border: none;
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              font-weight: 900;
              margin-left: 20px;
              margin-right: 20px;
            }
            .policy-menu-wrap-dw {
              border-top: 1px solid #ddd;
              margin-top: 15px;
              padding-top: 5px;
            }
            .policy-menu-wrap {
                padding: 0 20px 8px;
            }
            .policy-menu {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-left: 0;
            }
            .policy-menu__link {
              padding: 8px 0 !important;
              display: flex !important;
              border: none !important;
              border-radius: 0 !important;
              align-items: center;
              color: #444;
              font-size: 16px;
              font-weight: 400;
              &:hover {
                color: #00A19F;
              }
              .support-icons {
                font-size: 20px;
                margin-right: 16px;
              }
            }
            .need-help-text-wrap {
              margin: 0 20px;
              border-top: 1px solid #ddd;
              padding-top: 13px;
              padding-top: 16px;
              .need-help-text {
                color: #999;
                font-size: 12px;
                line-height: 24px;
                text-transform: uppercase;
                margin-bottom: 8px;
                font-weight: 600;
              }
              .contact-mail {
                border: 1px solid #444;
                border-radius: 4px;
                display: flex;
                align-items: center;
                color: #000;
                font-size: 14px;
                line-height: 17.57px;
                padding: 10px 14px;
                font-weight: 700;
                gap:10px;
                &:hover {
                  color: #00A19F;
                }
              }
              .contact-mail+a.contact-mail {
                margin-top: 10px;
              }
            }
            .whatsapp-cl {
              margin:10px 20px;
              font-size:14px;
              text-align: left;
            }
          }
        }   
      }
    }
  }
  .hamburger-inner {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    .bar {
        width: 20px;
        height: 2px;
        background: #000;
        transition: .1s ease-in-out;
        margin-top: 4px;
    }
    .bar:first-child {
      margin-top: 0;
    }
  }
}
.header-container {
  position: absolute;
  left: 0;
  width: 100%;
  display: none;
  top: 60px;
  opacity: 0;
  height: 0;
  background-color: #fff;
  -ms-transform: translateY(-2em);
  transform: translateY(-2em);
  z-index: 999;
  transition: all .3s ease;
  border-top: 1px solid #00A19F;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  &.header-container-claims {
    width: 260px;
    top: 60px;
    left: 0;
    box-shadow: none !important;
    border-top: 1px solid #00A19F;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::after {
      left: 14%;
    }
  }
  &::after {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border: 1px solid #00A19F;
    left: 23%;
    top: -7px;
    border-right: 0;
    border-bottom: 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
  }
  .header-products {
    margin-left: 9%;
    flex-direction: row;
    display: flex;
    flex: 75%;
    &.header-claims {
      margin-left: 0px;

    }
    .header-products__items {
      padding: 0 25px;
      flex: 25%;
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      .product-name {
        font-size: 16px;
        font-weight: 900;
        margin-top: 18px;
        margin-bottom: 15px;
      }
      .sub-products-container ul {
        list-style-type: none;
        padding-left: 0;
        li {
          margin-bottom: 15px;
          width: 100%;
          text-align: left;
          a {
            font-size: 14px;
            text-decoration: none;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: .7px;
            display: block;
            padding: 0;
            &:hover {
              color: #00A19F;
              text-shadow: 0 0 1px #00A19F;
            }
          }
        }
      }
    }
    .header-products__items-image-section {
      padding-top: 10px;
      text-align: left;
    }
  }
}
.backdrop-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #000;
  background-color: #000;
  box-shadow: 0 5px 7px 3px rgba(0,0,0,0.1);
  opacity: .5;
  display: block;
  @media (min-width: 576px) { 
    display: none;
  }
}
.header-mb-nav {
  @media (min-width: 576px) { 
    display: none;
  }
  width: 340px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 5px 7px 3px rgba(0,0,0,0.1);
  position: fixed;
  overflow-y: auto;
  top: 0;
  border-top: 2px solid #00A19F;
  transition: all .4s ease-in-out;
  transform: translateX(-100%);
  &.active {
    transform: translateX(0);
  }
  .header-mb-nav-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-top {
    padding: 25px 0 15px;
    .navbar-collpase__content {
      display: flex;
      font-size: 13px;
      justify-content: space-between;
    }
    .phone-link {
      width: 48px;
      height: 48px;
      .user-icon-mobile, .telephone-icon-mobile {
        position: relative;
        top: -5px;
        width: 100%;
        border-radius: 50%;
        height: auto;
        vertical-align: bottom;
        box-shadow: 0 1px 2px rgba(0,0,0, 0.2);
      }
    }
    #sidemenu-user-sign {
      flex: 0 0 65%;
      max-width: 65%;
      margin-left: 16px;
    }
    .sign-in-link-sidemenu {
      background-color: #00A19F;
      border-radius: 4px;
      padding: 10px 30px;
      display: block;
      color: #fff;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      font-weight: 900;
      width: 100%;
    }
    .eezee-right-arrow-icon {
      font-size: 37px;
      width: 25px;
    }
  }
  .separaror-h-mb {
    padding: 0 16px;
    border-top: 8px solid #ddd;
  }
  .nav.navbar-mb {
    display: block;
  }
  .nav__list-container {
    padding: 0 16px;
    border-top: 8px solid #ddd;
    position: relative;
    display: block;
    &.no-border {
      border-top: none;
    }
  }
  .nav .nav__list .nav-category {
    padding-left: 0;
    padding-bottom: 0;
    color: #999;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    padding-top: 15px;
    text-transform: uppercase;
  }
  .navbar-dropdown-inner:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  .nav-group-title {
    font-size: 16px;
    padding: 16px 0;
    margin: 0;
    color: #000;
    line-height: 1.4;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-dropdown-inner .nav-group-container {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 8px;
    display: block;
  }
  .navbar-dropdown-inner {
    li {
      border: 0;
      transition: all .1s ease-in-out;
      display: flex;
      margin-left: 15px;
      margin-right: 0;
    }
    .nav-direct-links {
      margin-top: 18px;
      li {
        margin-left: 0;
      }
    }
  }
 
  .navbar-dropdown-inner li:first-child {
    border-top: 0;
  }
  .navbar-dropdown-inner li>a {
    padding: 12px 0;
  }
  .navbar-dropdown-inner li:first-child>a {
    padding-top: 0;
  }
  .nav--company {
    display: block;
    text-align: center;
    border-top: 1px solid #ddd;
  }
  .nav--company>li {
    border-top: 0;
    display: inline-block;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .nav--company>li>a {
    font-weight: 400;
    padding: 15px;
    &:hover{
      color:#00A19F;
    }
  }
  .nav--company>li.nav__list-a::before {
    position: absolute;
    left: 0;
    height: 10px;
    background-color: #444;
    content: "";
    width: 1px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

