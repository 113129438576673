@import "../../styles/_shared.scss";

.car-wrapper.hero-unit {
  padding-top: 60px;
  .page-content {
    background-color: #f5f5f5;
  }
  .cr-content {
    padding: 30px 0;
    padding-left: 20px;
    .cr-header {
      color: #000;
      font-size: 36px;
      font-weight: 800;
      line-height: 43px;
      text-align: left;
      margin-bottom: 10px;
    }
    .cr-subheading {
      color: #0a5;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      text-align: left;
    }
  }
 
}
.content-info-list {
  h3 {
    font-weight: 600;
    font-size: 20px;
  }
}
.team-list strong{
  color: #00A19F;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 5px;
  display: inline-block;
}