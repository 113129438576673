@import "../../styles/_shared.scss";


.modal-success-email {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.5s;
  &.active {
    display: block;
  }
  &.inactive {
    display: none;
  }
}

.grey-bg {
  background-color: #f2f2f2;
}
.modal-email-success-content {
  background-color: #fefefe;
  padding: 20px;
  z-index: 1000;
  width: 400px;
  top: 50%;
  position: relative;
  margin: 0 auto 0 auto;
  height: 255px;
  border-radius: 8px;
  transform: translateY(-50%);
  animation: roadRunnerIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.email-heading {
  font-size: 20px;
  text-align: center;
  margin-top: 20%;
}
.emailclosebtn {
  position: absolute;
  bottom: 0;
  width: 90%;
}