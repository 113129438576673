@import "../../styles/_shared.scss";

.more-extra-products {
  padding-top: 60px;
  background-color:mintcream;
  border-bottom: 8px solid #00A19F;
  margin-bottom: 30px;
  .section-text-wrap {
    margin-bottom: 30px;
    margin-top: 15px;
  }
  .section-title {
    color: #000;
    font-weight: 800;
    font-size: 24px;
    margin: 0 0 10px 0;
    display: inline-block;
    width: 100%;
    @media (max-width: 576px) {
      font-size: 20px;
      line-height: 1.6;
      text-align: center;
    }
  }
  .more-products-wrap {
    border-radius: 5px;
    padding: 30px 10px 0;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding: 30px 0 10px;
    padding-top: 0;
    .more-products-title {
      text-align: left;
      margin-bottom: 0;
      @media (max-width: 576px) {
        text-align: center;
      }
      .more-products-title__text {
        display: inline-block;
        background-color: #f5f5f5;
        border-radius: 14px;
        padding: 8px 10px;
        color: #444;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }
  .more-extra-products-wrap {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
    justify-content: center;
    .more-products__list {
      margin-bottom: 0;
      display: flex;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      &:nth-child(odd){
        .more-products__img-wrap {
          color: #d14c4f;
            .circle-rotation{
              border-color: #d14c4f;
            }
          }
      }
      .more-products__link {
        display: -ms-flexbox;
        display: flex;
        height: 130px;
        padding: 10px;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        width: 100%;
        // border: 1px solid #ddd;
        padding: 10px 5px;
        transition: all 150ms ease-in-out;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 4px;
        min-height: 95px;
        &:hover {
          /* border-color: #00a19f;
          box-shadow: 0 0.45rem 0.45rem 0 rgba(50, 50, 50, 0.2); */
          text-decoration: none;
          transform: translateY(-3px);
        }
      }
      .more-products__img-wrap {
        position: relative;
        text-align: left;
        flex: 1 0 auto;
        margin: 0 auto 0px;
        transition: all 300ms ease-in-out;
        font-size: 24px;
        align-items: center;
        color: #00a19f;

        border-radius: 50%;
        padding: 15px;
        max-height: 65px;
        height: 65px;
        max-width: 65px;
        width: 65px;
        margin-bottom: 12px;
        text-align: center;
        // &:hover, &:focus {
        //   color: #00213d;
        // }
        &.plane-icon{
          color: #0093d7;
          .circle-rotation{
            border-color: #0093d7;
          }
        }
        &.bus-icon{
          color: #ff9d00;
          .circle-rotation{
            border-color: #ff9d00;
          }
        }
        &.tractor-icon{
          color: #007ff7;
          .circle-rotation{
            border-color: #007ff7;
          }
        }
        &.car-icon{
          color: #ff7300;
          .circle-rotation{
            border-color: #ff7300;
          }
        }
        &.home-icon{
          color: #a3903a;
          .circle-rotation{
            border-color: #a3903a;
          }
        }
        &.goods-icon, &.shop-icon{
          color: #725edb;
          .circle-rotation{
            border-color: #725edb;
          }
        }
        &.auto-icon{
          .circle-rotation{
            border-color: #00A19F;
          }
        }
        &.marine-icon, &.bike-icon{
          color: #055b70;
          .circle-rotation{
            border-color: #055b70;
          }
        }
        .circle-rotation{
          background-clip: content-box;
          border: 1px dashed #00A19F;
          animation: spin 10s linear infinite;
          border-radius: 50%;
          position: absolute;
          left:0;
          top:0;
          width: 100%;
          height: 100%;
        }
        .more-products__img {
          // height: 70px;
          width: 70px;
          max-width: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          max-height: 70px;
          transform: translate(-50%, -50%);
          &.auto-img {
            position: relative;
            max-width: 30px;
            top: 9px;
            left: 15px;
          }
        }
        .pw-img.loaded {
          filter: blur(0);
        }
      }
      .more-products__text {
        color: #000;
        font-family: "Cabin";
        font-size: 14px;
        font-weight: 700;
        padding: 0;
        font-weight: 700;
        text-transform: capitalize;
        // line-height: 16px;
        transition: all 300ms ease-in-out;
        text-align: center;
        display: inline-block;
        -ms-flex: 1;
        flex: 1;
      }
    }
  }
}

@keyframes spin { 
  100% { 
    transform: rotateZ(360deg);
  }
}