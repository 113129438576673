@import "../../styles/_shared.scss";

.what-we-do-container {
  padding: 0px 0 30px;
  .section-title {
    font-size: 25px;
    font-weight: 800;
    margin: 0 0 30px 0;
  }
  ul {
    li {
      font-size: 18px;
      line-height: 40px;
      list-style: none;
      background-image: url("../../assets/images/tick.png");
      background-repeat: no-repeat;
      background-size: 24px;
      background-position-y: center;
      padding-left: 36px;
    }
    @media (max-width: 767px) {
      padding-left: 10px;
      li {
        background-position-y: 4px;
        margin-bottom: 15px;
        line-height: 1.5;
      }
    }
  }
  .section-pad {
    @media (max-width: 576px) {
      padding: 20px 0 10px;
    }
  }
}
