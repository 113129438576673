
@import "./styles/_shared.scss";


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.txt-center {
  text-align: center;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  color: #06223C;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  overflow-x: hidden;
  font-family: 'Cabin';
}
.container {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  max-width: 1136px;
}
.container .row {
  margin-left: 0;
  margin-right: 0;
}
.section-pad {
  padding: 50px 0;
}
.bg-gray {
  background-color: #f5f5f5;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.bb-ddd {
  border-bottom: 1px solid #ddd;
}
.mr-b-40 {
  margin-bottom: 40px;
}
.brand-color{
  color: #00A19F;
}
a {
  text-decoration: none;
  color: #06223C;
}
.eezee-link {
  color: #00A19F;
  cursor: pointer;
}
.eezee-link:hover {
  color: #00A19F;
}
h2.h2-v2 {
  font-size: 25px;
  color: #444;
  font-weight: 700;
}
.mb-30 {
  margin-bottom: 30px;
}
.bold {
  font-weight: 700;
}
ul {
  margin: 0;
}
.text-left {
  text-align: left;
}
.font-bold {
  font-weight: 700;
}
.primary-button {
  width: 100%;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 0 45px;
  text-align: center;
  margin-bottom: 20px !important;
  border: 2px solid #eef3fb;
  outline: none;
  background-color: #06223C;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  color: #fff;
}
button, select {
  text-transform: none;
}

select {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dadada;
  padding: 0 20px;
  border-radius: 0;
  outline: none;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  font-weight: normal;
  transition: border 0.5s;
}
.input-text {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dadada;
  transition: border 0.5s;
  padding: 0 20px;
  border-radius: 0;
  outline: none;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  font-weight: normal;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0,0);
}

.fa-chevron-down:before {
  content: "\f078";
}

.footercollapsen{
  display: none;
}