@import "../../styles/_shared.scss";

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer-title {
  font-size: 20px;
  color: #444;
}
.row-main {
  pointer-events: none;
  margin: 0;
}
.row-main+.row-main {
  margin-top: 15px;
}
.sm-arrow-icon {
  position: absolute;
  top: 16px;
  right: 15px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  transition: all 300ms ease-in-out;
  &.sm-arrow-d-icon {
    @media (max-width: 576px) { display: none; }
  }
  &.sm-arrow-m-icon {
    margin-top: 12px;
    @media (min-width: 576px) { display: none; }
  }
}
.row-main-list {
  pointer-events: none;
  border: 1px solid #ddd;
  position: relative;
  border-radius: 4px;
  padding: 15px 0;
  .products-list-item {
    border: 0;
    pointer-events: none;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: 576px) { 
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-top: 10px;
    }
    .heading-text-wrap {
      padding: 0;
      @media (max-width: 576px) { 
        padding: 15px 20px 15px 0px;
      }
      .heading-text {
        position: relative;
        text-align: left;
        .heading-text__link {
          color: #444;
          font-weight: 600;
          line-height: 16px;
          pointer-events: auto;
          font-size: 14px;
          cursor: default;
        }
      }
    }
  }
}
.products-list {
  padding-left: 0;
  list-style: none;
  margin: 0;
}
.products-list-wrap {
  border-top: 0;
  display: block;
  margin-right: 0;
  padding-bottom: 0;
  text-align: left;
  transition: all .5s ease-in-out;
  @media (max-width: 576px) { 
    border-top: 1px solid #ddd;
  }
  ul {
    li {
      list-style: none;
    }
  }
}
.products-list__link {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  display: inline-block;
  pointer-events: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    color: #00A19F;
  }
}