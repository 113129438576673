@font-face {
  font-family: 'FontAwesome';
  src: url('../../node_modules/font-awesome/fonts/fontawesome-webfont.eot');
  src: url('../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix') format("embedded-opentype"),
  url('../../node_modules/font-awesome/fonts/fontawesome-webfont.woff') format("woff"),
  url('../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf') format("truetype"),
  url('../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#fontawesomeregular') format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('../fonts/muli.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/cabin-regular-webfont.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}