@import "../../styles/_shared.scss";

.car-wrapper.hero-unit {
  padding-top: 60px;
  .page-content {
    background-color: #f5f5f5;
  }
  .cr-content {
    padding: 30px 0;
    padding-left: 20px;
    .cr-header {
      color: #000;
      font-size: 36px;
      font-weight: 800;
      line-height: 43px;
      text-align: left;
      margin-bottom: 10px;
    }
    .cr-subheading {
      color: #0a5;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      text-align: left;
    }
  }
 
}
.content-info-list {
  h3 {
    font-weight: 600;
    font-size: 20px;
  }
}

.contact-us-page {
  // background-image: url(/assets/images/gplay.png);
  background-position: center;
  /* background-repeat: no-repeat;
  background-size: cover; */
  padding: 40px 0 30px;
  .heading {
    text-align: center;
    margin-bottom: 16px;
    color: #000;
    font-weight: 800;
    font-size: 25px;
  }
  .description {
    text-align: center;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .mobile-space {
    @media(max-width:767px) {
      margin-top: 1.5rem !important;
    }
  }
  ul.cnss-social-icon li {
    vertical-align: middle;
    float: none !important;
    width: auto !important;
    margin: 0 !important;
    list-style-type: none !important;
    border: none !important;
    padding: 0 !important;
    background: none !important;
    line-height: normal !important;
  }
  ul {
    padding-left: 0px;
    padding-top: 8px;
  }
  .fa.fa-facebook,
  .fa.fa-twitter,
  .fa.fa-linkedin,
  .fa.fa-instagram {
    font-size: 25px;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    background-color: #06223c;
    color: #fff;
    text-align: center;
    padding: 7px 5px 5px 5px;
  }
  .fa.fa-twitter,
  .fa.fa-linkedin,
  .fa.fa-instagram {
    padding-top: 5px;
  }
  .cnss-social-icon {
    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
  .info-1 {
    width: 22%;
    @media (max-width: 576px) {
      width: 100%;
      text-align: center;
    }
  }
  .info-2 {
    @media (max-width: 576px) {
      margin-top: 32px;
    }
  }
  .contact-us-form-div {
    display: flex;
    flex-direction: row;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
  .contact-us-form-div-inner1 {
    width: 50%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .contact-us-form-div-inner2 {
    width: 50%;
    margin-left: 20px;
    @media (max-width: 576px) {
      width: 100%;
      margin-left: 0px;
    }
  }
}

.c-wrapper {
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.contact_banner_img {
  margin-bottom: 80px;
  img{
    width: 100%;
  }
}

.send-btn {
  position: relative;
}

.button--loading {
  color: #000;
  background: #000;
  opacity: 0.5;
  cursor: default !important;
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #fff;
    border-right-color: #fff;
    border-left-color: #fff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }
}